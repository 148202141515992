import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cn from 'clsx';
import isEmail from 'validator/lib/isEmail';
// import ContactUsContainer, {
//   ContactUsModal,
// } from '../ContactUs/ContactUs.styled';

const Registration = ({ showFormModal, toggleFormModal }) => {
  const [input, setInput] = useState({
    email: '',
    displayName: '',
    twitter: '',
    linkedin: '',
    reach: '',
    // referredBy: '',
  });
  const [sending, setSending] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const inputHandler = useCallback(({ target: { name, value } }) => {
    setInput((state) => ({ ...state, [name]: value }));
  }, []);

  const registrationFormSubmitHandler = useCallback(
    (e) => {
      e.preventDefault();

      if (
        !input.email ||
        !isEmail(input.email) ||
        !input.reach ||
        !input.displayName
      )
        return;

      setSending(true);

      axios
        .post(
          'https://ruttl-admin.web.app/api/affiliates?action=add',
          Object.fromEntries(
            new Map(Object.entries(input).filter((i) => i[1].length > 0)),
          ),
        )
        .then(() => {
          setInput({
            email: '',
            displayName: '',
            twitter: '',
            linkedin: '',
            reach: '',
            // referredBy: '',
          });
          setSending(false);
          setShowThankYou(true);
        })
        .catch(() => {
          setSending(false);
        });
    },
    [input],
  );

  useEffect(() => {
    setShowThankYou(false);
  }, []);

  return (
    <div
      id="affiliate-modal"
      className={cn(['contact-us-modal', { show: showFormModal }])}>
      <div className="contact-us-container" style={{ maxWidth: 750 }}>
        <button
          type="button"
          className="button-unstyled close-button"
          onClick={toggleFormModal}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="32"
              d="M368 368L144 144M368 144L144 368"
            />
          </svg>
        </button>
        {showThankYou ? (
          <>
            <h3 style={{ marginTop: 0, marginBottom: 24, fontSize: 24 }}>
              Request submitted successfully!
            </h3>
            <p>
              Thank you for showing interest. We have received your request. We
              will now review your request and keep you posted on the next
              steps.
            </p>
          </>
        ) : (
          <>
            <h3 style={{ marginTop: 0, marginBottom: 0, fontSize: 24 }}>
              Interested in ruttl&apos;s Affiliate Program
            </h3>
            <p style={{ marginTop: 5 }}>
              <small>We&apos;re offering commissions of 25%.</small>
            </p>
            <form
              onSubmit={registrationFormSubmitHandler}
              className="users-form smaller-labels">
              <div className="mb-2">
                <label htmlFor="affiliate-email">
                  Email Address *
                  <br />
                  <input
                    type="email"
                    name="email"
                    id="affiliate-email"
                    placeholder="Enter email address"
                    value={input.email}
                    onChange={inputHandler}
                    required
                  />
                </label>
              </div>
              <div className="mb-2">
                <label htmlFor="affiliate-name">
                  Full Name *
                  <br />
                  <input
                    type="text"
                    name="displayName"
                    id="affiliate-name"
                    placeholder="Enter full name"
                    value={input.displayName}
                    onChange={inputHandler}
                    required
                  />
                </label>
              </div>
              <div className="mb-2">
                <label htmlFor="affiliate-twitter">
                  Twitter profile link
                  <br />
                  <input
                    type="text"
                    name="twitter"
                    id="affiliate-twitter"
                    placeholder="Enter Twitter profile link"
                    value={input.twitter}
                    onChange={inputHandler}
                  />
                </label>
              </div>
              <div className="mb-2">
                <label htmlFor="affiliate-linkedin">
                  LinkedIn profile link
                  <br />
                  <input
                    type="text"
                    name="linkedin"
                    id="affiliate-linkedin"
                    placeholder="Enter LinkedIn profile link"
                    value={input.linkedin}
                    onChange={inputHandler}
                  />
                </label>
              </div>
              <div className="mb-2">
                <label htmlFor="affiliate-reach">
                  Are you a part of any community (Example - Discord, Facebook
                  Group, etc.) where you can promote ruttl? If yes, then please
                  mention your approximate reach below. *
                  <br />
                  <textarea
                    name="reach"
                    id="affiliate-reach"
                    placeholder="Enter answer"
                    value={input.reach}
                    onChange={inputHandler}
                    required
                    style={{ resize: 'none' }}
                  />
                </label>
              </div>
              {/* <div className="mb-2">
                <label htmlFor="affiliate-linkedin">
                  Referral email
                  <br />
                  <input
                    type="text"
                    name="referredBy"
                    id="affiliate-referredBy"
                    placeholder="Enter referral email"
                    value={input.referredBy}
                    onChange={inputHandler}
                  />
                </label>
              </div> */}
              <button
                type="submit"
                className="submit-button"
                disabled={
                  sending ||
                  !input.email ||
                  !isEmail(input.email) ||
                  !input.reach ||
                  !input.displayName
                }
                style={{ padding: '14px 40px', fontSize: 16 }}>
                {sending ? 'Sending...' : 'Submit'}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

Registration.propTypes = {
  showFormModal: PropTypes.bool.isRequired,
  toggleFormModal: PropTypes.func.isRequired,
};

export default Registration;
