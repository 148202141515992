import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'styles/components/OfferCoupon.scss';
import SignupModal from '../SignupModal/SignupModal';

const OfferCoupon = ({
  id,
  stripeColor,
  saveAmount,
  discount,
  desc,
  code,
  product,
}) => {
  const [showmodal, setShowmodal] = useState(false);
  const showSignupModal = () => setShowmodal((state) => !state);

  const clickHandler = () => {
    if (product === 'Customer Feedback') {
      window.location.assign('https://chat.ruttl.com/');
    } else {
      showSignupModal();
    }
  };

  // useEffect(() => {
  //   const myinput = document.querySelector(`#myinput${id}`);
  //   const copy = document.querySelector(`.copy${id}`);
  //   if (myinput) {
  //     myinput.addEventListener('mouseover', () =>
  //       copy.classList.remove('hide'),
  //     );
  //     myinput.addEventListener('mouseleave', () => copy.classList.add('hide'));
  //     myinput.addEventListener('click', () => {
  //       copy.classList.add('left50');
  //       copy.innerText = 'Copied';
  //       myinput.select();
  //       document.execCommand('copy');
  //       myinput.classList.add('success');
  //       setTimeout(() => {
  //         copy.classList.remove('left50');
  //         copy.innerText = 'Copy';
  //         myinput.classList.remove('success');
  //       }, 4000);
  //     });
  //   }
  // }, []);

  // const coupon = () => {
  //   const showCodebutton = document.querySelector(`.btn${id}`);
  //   showCodebutton.classList.add('animation');
  // };
  return (
    <section className="offer-coupon-section">
      <div className="single-coupon first-coupon center">
        <div className="coupon-desc">
          <span className="span" style={{ background: stripeColor }}>
            {saveAmount}
          </span>
          <p className="discount">{discount}</p>
          <p className="desc">{desc}</p>
          <div className="show-code-wrapper">
            <span className="code-wrapper">
              <span className={`copy${id} copy hide`}>copy</span>
              <input
                id={`myinput${id}`}
                className="code"
                value={code}
                // size={code.length < 6 ? 2 : 5}
              />
              <button
                type="button"
                className={`show-code btn${id}`}
                // onClick={() => coupon()}>
                onClick={clickHandler}>
                Show Code
              </button>
            </span>
          </div>
        </div>
      </div>
      {showmodal && (
        <SignupModal
          toggleModal={showSignupModal}
          heading="Sign up to view deals"
          product={product}
        />
      )}
    </section>
  );
};
OfferCoupon.defaultProps = {
  // data: null,
  // limitFaq: null,
  // headingCTA: false,
  // toggleContactUs: () => {},
  // greyBg: false,
};

OfferCoupon.propTypes = {
  id: PropTypes.string,
  stripeColor: PropTypes.string,
  saveAmount: PropTypes.string,
  discount: PropTypes.string,
  desc: PropTypes.string,
  code: PropTypes.string,
  product: PropTypes.string,
};

export default OfferCoupon;
