/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
// import { Link } from 'gatsby';
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';
import axios from 'axios';
// import isEmail from 'validator/lib/isEmail';
// import ContactUsContainer, {
//   ContactUsModal,
// } from 'components/ContactUs/ContactUs.styled';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
// import { nanoid } from 'nanoid';
// import { Main, TeamDropdown } from 'styles/pricing';
// import { TrustedBy } from 'styles';
// import GetStarted from 'components/GetStarted';
// import FAQ from '../components/Faq';
import {
  // customerNumber,
  // brandLogos,
  pricingTableData,
  getErrorMessage,
  bugTrackingPricingTableData,
  customerFeedbackPricingTableData,
} from '../utils/contentProvider';
import { signUp } from '../utils/contentProvider/function';
// import stripeCountries from '../utils/stripeCountries';
import useForm from '../hooks/useForm';
import { isValidEmail } from '../utils/validations';
import Registration from '../components/Affiliate/Registration';
import FeatureContainer from '../components/FeatureContainer';
import OfferCoupon from '../components/OfferCoupon/OfferCoupon';
import 'styles/pricing.scss';
import { PricingTable } from '../components/PricingTable/PricingTable';
const views = {
  monthly: 0,
  yearly: 1,
  lifetime: 2,
};

const pricingProducts = [
  {
    id: 0,
    productName: 'Website Feedback',
    freeMonthly: 0,
    freeYearly: 0,
    standardMonthly: 15,
    standardYearly: 13,
    proMonthly: 40,
    proYearly: 33,
  },
  {
    id: 1,
    productName: 'Bug Tracking',
    freeMonthly: 0,
    freeYearly: 0,
    standardMonthly: 39,
    standardYearly: 33,
    proMonthly: 79,
    proYearly: 66,
  },
  {
    id: 2,
    productName: 'Customer Feedback',
    freeMonthly: 0,
    freeYearly: 0,
    standardMonthly: 49,
    standardYearly: 41,
    proMonthly: 99,
    proYearly: 83,
  },
  {
    id: 3,
    productName: 'Mobile App Feedback',
    freeMonthly: 0,
    freeYearly: 0,
    standardMonthly: 39,
    standardYearly: 33,
    proMonthly: 79,
    proYearly: 66,
  },
];

const pricingTables = [
  {
    id: 0,
    table: pricingTableData,
    enterpriseData: {
      content:
        'Streamline the process of website feedback with unlimited projects and collaborators, run ruttl on your custom domain and customise it in your brand colours with our enterprise plan.',
      subtext: 'Simplified website feedback process for your enterprise',
      product: 'Website Feedback Tool',
    },
  },
  {
    id: 1,
    table: bugTrackingPricingTableData,
    enterpriseData: {
      content:
        'Streamline the bug tracking process with unlimited projects and collaborators, run ruttl on your custom domain and customise it in your brand colours with our enterprise plan.',
      subtext: 'Simplified bug tracking process for your enterprise',
      product: 'Bug Tracking',
    },
  },
  {
    id: 2,
    table: customerFeedbackPricingTableData,
    enterpriseData: {
      content:
        'Streamline the process of customer feedback with unlimited support agents, run the support dashboard on your custom domain and customise it in your brand colours with our enterprise plan.',
      subtext: 'Simplified customer feedback process for your enterprise',
      product: 'Customer Feedback',
    },
  },
  {
    id: 3,
    table: bugTrackingPricingTableData,
    enterpriseData: {
      content:
        'Streamline the bug tracking process with unlimited projects and collaborators, run ruttl on your custom domain and customise it in your brand colours with our enterprise plan.',
      subtext: 'Simplified bug tracking process for your enterprise',
      product: 'Bug Tracking',
    },
  },
];

const OffersPage = ({ location }) => {
  const [currentTab, setCurrentTab] = useState(views.yearly);
  const [teamDropdown, setTeamDropdown] = useState(false);
  const [teamMembers, setTeamMembers] = useState(5);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [websitePlan, setWebsitePlan] = useState('monthly');
  // const [userCurrency, setUserCurrency] = useState('default');
  // const [userEmail, setUserEmail] = useState('');
  const [fetchingLink /* setFetchingLink */] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [position, setPosition] = useState('');
  const [showDemoCallModal, setShowDemoCallModal] = useState(false);
  const [showFormModal, setFormModal] = useState(false);

  const toggleFormModal = useCallback(() => {
    setFormModal((state) => !state);
  }, []);

  const toggleTab = (tab) => () => setCurrentTab(tab);

  const toggleTeamDropdown = () => setTeamDropdown((state) => !state);

  const updateTeamMembers = (e) => {
    setTeamMembers(Number(e.target.closest('button').dataset.size));
    setTeamDropdown(false);
  };

  const getPricingTable = () =>
    pricingTables.find((table) => table.id === productTabs).table;

  const getEnterpriseData = () =>
    pricingTables.find((value) => value.id === productTabs).enterpriseData;

  const togglePurchaseModal =
    (payload = false) =>
    // eslint-disable-next-line indent
    () =>
      // eslint-disable-next-line indent
      setShowPurchaseModal(payload);

  const getProPlanValue = () =>
    websitePlan === 'yearly'
      ? pricingProducts[productTabs].proYearly
      : pricingProducts[productTabs].proMonthly;

  const getStandardPlanValue = () =>
    websitePlan === 'yearly'
      ? pricingProducts[productTabs].standardYearly
      : pricingProducts[productTabs].standardMonthly;

  const getTeamPlanValue = () =>
    websitePlan === 'yearly'
      ? pricingProducts[productTabs].teamYearly
      : pricingProducts[productTabs].teamMonthly;

  // const inputChangeHandler = (e) => {
  //   if (e.target.name === 'country') setUserCurrency(e.target.value);
  //   if (e.target.name === 'customer-email') setUserEmail(e.target.value);
  // };
  // const paySubmitHandler = (e) => {
  //   e.preventDefault();

  //   setFetchingLink(true);

  //   axios
  //     .post(
  //       `https://us-central1-rally-brucira.cloudfunctions.net/stripeCreateCheckoutSession`,
  //       {
  //         planID: `team-${teamMembers}`,
  //         planDescription: `Team-${teamMembers} plan`,
  //         cancelURL: 'https://ruttl.com/pricing/',
  //         successURL: 'https://web.ruttl.com/signup',
  //         email: userEmail,
  //         interval: currentTab === 0 ? 'month' : 'year',
  //         currency: stripeCountries
  //           .find(({ countryCode }) => countryCode === userCurrency)
  //           .currencyCode.toLowerCase(),
  //       },
  //     )
  //     .then((response) => {
  //       if (typeof response.data === 'string') window.location = response.data;
  //     })
  //     .catch(() => {
  //       setFetchingLink(false);
  //     });
  // };

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();

        document.querySelector(anchor.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  }, []);

  const formProps = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      company: '',
    },
    validations: {
      name: {
        required: true,
      },
      email: {
        required: true,
        rules: [
          {
            validator: (value) => isValidEmail(value),
            message: getErrorMessage('error', 'email'),
          },
        ],
      },
    },
    onSubmit: (payload, reset) => {
      (async () => {
        try {
          setLoading(true);

          await axios.post(
            'https://us-central1-rally-brucira.cloudfunctions.net/contact',
            {
              subject: `Enterprise-Custom-${pricingTables[productTabs].enterpriseData.product}`,
              email: payload.email,
              message: `${payload.name} has shown interest in ${teamMembers} members Custom Enterprise plan.`,
            },
          );

          // reset form
          reset();

          // setPosition(p); // set position in state

          setShowDemoCallModal(true); // open modal
        } catch (e) {
          // eslint-disable-next-line no-alert
          window.alert('Something went wrong, try again');
        } finally {
          setLoading(false);
        }
      })();
    },
  });

  const handleChange = (e) => {
    formProps.onChange(e.target.name, e.target.value);
  };

  // useEffect(() => {
  //   const myinput1 = document.querySelector('#myinput');
  //   const copy = document.querySelector('.copy');
  //   if (myinput1) {
  //     myinput1.addEventListener('mouseover', () =>
  //       copy.classList.remove('hide'),
  //     );
  //     myinput1.addEventListener('mouseleave', () => copy.classList.add('hide'));
  //     myinput1.addEventListener('click', () => {
  //       copy.innerText = 'Copied';
  //       myinput1.select();
  //       document.execCommand('copy');
  //       myinput1.classList.add('success');
  //       setTimeout(() => {
  //         copy.innerText = 'Copy';
  //         myinput1.classList.remove('success');
  //       }, 4000);
  //     });
  //   }
  // }, []);

  // const coupon1 = () => {
  //   const btn1 = document.querySelector('.btn1');
  //   btn1.classList.add('animation');
  // };

  const [productTabToggleMobile, setProductTabToggleMobile] = useState(false);

  const [productTabs, setProductTabs] = useState(0);

  function productTabsPricingTrigger(value) {
    return () => {
      setProductTabs((state) => (state === value ? value : value));
      setProductTabToggleMobile(false);
    };
  }

  const productTabsMobileTrigger = () => {
    setProductTabToggleMobile((state) => !state);
  };

  useEffect(() => {
    // const CONTAINER_PADDING_HALF = '0.5em';

    const pointer = document.getElementById('js-pointer');
    const navList = document.querySelector('.product-list-tabs');
    const links = Array.from(navList.querySelectorAll('button'));

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < links.length; i++) {
      const current = links[i];
      current.dataset.order = `${i * 100}%`;
      current.addEventListener('click', movePointer);
    }

    // set moving cursor to first element
    pointer.style.transform = `translate3d(${links[0].offsetLeft}px,0,0)`;
    pointer.style.width = `${links[0].offsetWidth}px`;
    pointer.style.height = `${links[0].offsetHeight}px`;

    function movePointer(e) {
      const currentWidth = e.currentTarget.offsetWidth;
      const currentLeft = e.currentTarget.offsetLeft;
      const currentheight = e.currentTarget.offsetHeight;

      pointer.style.transform = `translate3d(${currentLeft}px,0,0)`;
      pointer.style.width = `${currentWidth}px`;
      pointer.style.height = `${currentheight}px`;
    }
  }, []);

  return (
    <Layout
      location={location}
      hideTopBar
      pricingPage
      product={pricingTables[productTabs].enterpriseData.product}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Ruttl Deals and Offers"
            description="Current deals and offers for Ruttl"
            url="https://ruttl.com/offers/"
          />
          <main className="styled-pricing-main">
            <section className="pricing-hero">
              <div className="container">
                <div className="pricing-hero-top">
                  {/* <div className="flex flex-wrap justify-content-between align-items-center"> */}
                  <div className="center">
                    <h1>Exclusive deals just for you</h1>
                    <p>
                      Because we know what you need for Fastest bug tracking &
                      website feedbacks
                    </p>
                  </div>
                </div>
                <div className="coupon-wrapper">
                  <OfferCoupon
                    id="1"
                    saveAmount="Save $144 🔥"
                    discount="30% OFF"
                    desc="Offer applicable on Yearly Pro Plan"
                    code="RUTTL30"
                    product={pricingTables[productTabs].enterpriseData.product}
                  />
                  <OfferCoupon
                    id="2"
                    saveAmount="Save $36 🔥"
                    discount="20% OFF"
                    desc="Offer applicable on Yearly Standard Plan"
                    code="HELLO20"
                    product={pricingTables[productTabs].enterpriseData.product}
                  />
                  <OfferCoupon
                    id="3"
                    saveAmount="Save $27 🔥"
                    discount="15% OFF"
                    desc="Offer applicable on all monthly Plans"
                    code="RUTTL15"
                    product={pricingTables[productTabs].enterpriseData.product}
                  />
                </div>
              </div>
            </section>
            <section className="pricing-table offer-table" id="pricing-table">
              <div className="container">
                <div className="offers center">
                  <h2>Pricing and plans</h2>
                </div>
              </div>
            </section>

            <div>
              <div className="top-pricing-tabs pricing-table-tabs">
                <div className="container">
                  <div className="flex align-items-center">
                    <p>Products:</p>
                    <div className="visible-mob flex-1">
                      <button
                        onClick={productTabsMobileTrigger}
                        type="button"
                        className={`mob-price-product-toggle ${
                          productTabToggleMobile ? 'active' : ''
                        }`}>
                        {pricingProducts.map(({ id, productName }) => (
                          <span
                            key={id}
                            className="center"
                            style={{
                              display: productTabs === id ? 'block' : 'none',
                            }}>
                            {productName}
                          </span>
                        ))}
                        <svg
                          width="13"
                          height="8"
                          viewBox="0 0 13 8"
                          fill="none">
                          <path
                            d="m1.333 1.666 5.334 5.333L12 1.666"
                            stroke="#150546"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div id="magic-nav">
                      <span id="js-pointer"></span>

                      <div
                        className={`product-list-tabs relative  ${
                          productTabToggleMobile ? 'active' : ''
                        }`}>
                        {pricingProducts.map(({ id, productName }) => (
                          <button
                            key={id}
                            type="button"
                            className={productTabs === id ? 'active' : ''}
                            onClick={productTabsPricingTrigger(id)}>
                            {productName}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <PricingTable
                pricingData={getPricingTable()}
                views={views}
                currentTab={currentTab}
                toggleSignup={toggleSignup}
                toggleTab={toggleTab}
                websitePlan={websitePlan}
                setWebsitePlan={setWebsitePlan}
                getProPlanValue={getProPlanValue}
                getStandardPlanValue={getStandardPlanValue}
                getTeamPlanValue={getTeamPlanValue}
                togglePurchaseModal={togglePurchaseModal}
                enterpriseData={getEnterpriseData()}
                offersPage
              />
            </div>

            <section className="container offers-page-features">
              <h2 className="center">
                Each plan has features to help your team work more efficiently
              </h2>
              <FeatureContainer>
                <div className="feature-set-single" id="comment-live-website">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <img
                        src="/assets/img/leave-comments-website.jpg"
                        alt="illustration of leaving comment on live website"
                        title="illustration of leaving comment on live website"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      // style={{ transform: ' translateY(-40px)' }}
                    >
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '420px' }}>
                        Use ruttl standard to get websites live faster
                      </h2>
                      <p>
                        Use comment and edit mode to give feedback faster and
                        save both design and development time and money
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Upgrade to Standard
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single" id="comment-live-website">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <img
                        src="/assets/img/bug-tracking-illustration.svg"
                        alt="illustration of leaving comment on live website"
                        title="illustration of leaving comment on live website"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      // style={{ transform: ' translateY(-40px)' }}
                    >
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '420px' }}>
                        Report Bugs and Review web apps with ruttl Pro
                      </h2>
                      <p>
                        Make your QA process efficient with our Bug Tracking
                        Tool. Integrate it with Jira, Trello and many more
                      </p>
                      <div
                        className="button-container"
                        style={{
                          margin: '-6px',
                          justifyContent: 'flex-start',
                        }}>
                        <button
                          type="button"
                          onClick={() => signUp(toggleSignup)}
                          className="button">
                          Upgrade to Pro
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single" id="comment-live-website">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <img
                        src="/assets/img/pricing-upgrade-3.png"
                        alt="illustration of leaving comment on live website"
                        title="illustration of leaving comment on live website"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      // style={{ transform: ' translateY(-40px)' }}
                    >
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '420px' }}>
                        ruttl&apos;s team plan helps you make the tool your own
                      </h2>
                      <p>
                        Get member management, CNAME & custom branding you need,
                        plus advanced support.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Upgrade to Team
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              {/* <div className="flex flex-wrap justify-content-center align-items-center">
                <div className="features-card card1">
                  <div>
                    <img
                      loading="lazy"
                      src="/assets/img/offers-features-img1.svg"
                      alt="offers-features-img1"
                    />
                  </div>
                  <h5>Website Feedback</h5>
                  <p>
                    Leave comments on live websites and make real time edits so
                    that you can share precise design change values with
                    developers!
                  </p>
                </div>
                <div className="features-card card2">
                  <div>
                    <img
                      loading="lazy"
                      src="/assets/img/offers-features-img2.svg"
                      alt="offers-features-img2"
                    />
                  </div>
                  <h5>Web App Reviewing</h5>
                  <p>
                    Collect feedback and comments directly on live pages of web
                    applications and ship your product faster.
                  </p>
                </div>
                <div className="features-card card3">
                  <div>
                    <img
                      loading="lazy"
                      src="/assets/img/offers-features-img3.svg"
                      alt="offers-features-img3"
                    />
                  </div>
                  <h5>Bug Tracking</h5>
                  <p>
                    Capture, track, resolve and report on bugs and issues on
                    digital products and websites.
                  </p>
                </div>
              </div> */}
            </section>
          </main>
          <Registration
            showFormModal={showFormModal}
            toggleFormModal={toggleFormModal}
          />
          <div
            id="purchase-modal"
            className={cn([
              'contact-us-modal',
              { show: typeof showPurchaseModal === 'number' },
            ])}>
            <div className="contact-us-container">
              <button
                type="button"
                className="button-unstyled close-button"
                disabled={fetchingLink}
                onClick={togglePurchaseModal(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="none"
                    stroke="#000000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="32"
                    d="M368 368L144 144M368 144L144 368"
                  />
                </svg>
              </button>
              {!showDemoCallModal ? (
                <>
                  <h3 style={{ marginTop: 0, marginBottom: 24, fontSize: 24 }}>
                    Contact Sales
                  </h3>
                  <form
                    autoComplete="off"
                    onSubmit={formProps.onSubmit}
                    id="contact-sales">
                    <div className="">
                      <div className="form-group">
                        <h4>
                          Name
                          {/* eslint-disable indent,react/jsx-indent */}
                          {formProps.getErrors('name').length
                            ? formProps.getErrors('name').map((e) => (
                                <span className="error" key={e.key}>
                                  {e.message}
                                </span>
                              ))
                            : null}
                          {/* eslint-enable indent,react/jsx-indent */}
                        </h4>
                        <label htmlFor="name">
                          <input
                            className="flex-grow-1 flex-basis-0"
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            onChange={handleChange}
                            value={formProps.values.name}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <h4 className="flex-grow-1">
                          Work Email
                          {/* eslint-disable indent,react/jsx-indent */}
                          {formProps.getErrors('email').length
                            ? formProps.getErrors('email').map((e) => (
                                <span className="error" key={e.key}>
                                  {e.message}
                                </span>
                              ))
                            : null}
                          {/* eslint-enable indent,react/jsx-indent */}
                        </h4>
                        <label htmlFor="email">
                          <input
                            type="text"
                            name="email"
                            placeholder="Enter your email"
                            onChange={handleChange}
                            value={formProps.values.email}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className="form-group">
                        <div className="team-member">
                          <h4>Team Members</h4>
                          <div className="pricing-team-dropdown">
                            <button
                              type="button"
                              className="button-unstyled"
                              onClick={toggleTeamDropdown}>
                              <strong>{teamMembers}</strong>
                              &nbsp;members
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="14px"
                                  height="100%"
                                  viewBox="0 0 512 512"
                                  fill="#fff"
                                  style={{ verticalAlign: 'middle' }}>
                                  <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path>
                                </svg>
                              </span>
                            </button>
                            <div
                              className={`team-dropdown ${
                                teamDropdown ? 'show' : ''
                              }`}>
                              {[5, 10, 15, 20, 25].map((i) => (
                                <button
                                  key={i}
                                  data-size={i}
                                  type="button"
                                  className="button-unstyled"
                                  onClick={updateTeamMembers}>
                                  {teamMembers === i ? (
                                    <strong>{`${i} members`}</strong>
                                  ) : (
                                    `${i} members`
                                  )}
                                </button>
                              ))}
                            </div>
                            {teamDropdown && (
                              <button
                                type="button"
                                className="button-unstyled absolute-inset"
                                onClick={toggleTeamDropdown}
                                style={{
                                  position: 'fixed',
                                  cursor: 'default',
                                }}>
                                <span />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        className="button button-green"
                        type="submit"
                        disabled={loading}>
                        {loading ? 'loading...' : 'Book Demo Call'}
                      </button>
                    </div>
                    {/* <button type="reset" onClick={formProps.reset}>Reset</button> */}
                  </form>
                </>
              ) : (
                <>
                  <h2>Thank you</h2>
                  <p>
                    Thanks for showing interest in our enterprise plan,
                    we&apos;d be glad to connect with you over a call to
                    understand your team requirement better. Please schedule a
                    call with us using the link below:
                  </p>
                  <div>
                    <a
                      className="button"
                      href="https://calendly.com/setup-demo-with-ruttl/30min"
                      target="_blank"
                      rel="noopener noreferrer">
                      Schedule a Demo
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

OffersPage.propTypes = {
  location: PropTypes.object,
};

export default OffersPage;
